import React, { createContext, useEffect, useReducer, useState } from 'react';
import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import api from '../services/api';
import accountReducer from '../store/accountReducer';
import Loader from "../components/Loader/Loader";
import socketio from 'socket.io-client'

import backendURL from '../services/backend'

function maker0(s) {


  for (var i = 0; i < s.length; i++) {

    s[i].id = s[i].ID
    s[i].title = s[i].DS_MENU
    s[i].type = s[i].TIPO
    s[i].url = s[i].URL
    s[i].icon = s[i].URL_ICON

  }

  return s


}


function maker1(s) {


  for (var i = 0; i < s.length; i++) {

    if (s[i].SELF_ID != null) {

      for (var e = 0; e < s.length; e++) {

        if (s[e].ID == s[i].SELF_ID) {

          if (s[e].hasOwnProperty('children') == false) {
            s[e].children = []
          }

          s[e].children.push(s[i])

        } else {

          if (s[e].hasOwnProperty('children') == true) {

            if (s[e].children.length > 0) {

              for (var o = 0; o < s[e].children.length; o++) {

                maker1(s[e].children[o])

              }

            }

          }

        }



      }


    }



  }


}

function maker2(s) {

  if (s.length > 0) {


    for (var i = 0; i < s.length; i++) {

      if (s[i].SELF_ID != null) {

        s[i].EX = false

      } else s[i].EX = true

    }



    s = s.filter(function (el) {
      return el.EX == true
    })

    var x = { items: s }

    return x

  }

  else return { items: [] }



}

//--------------------------------| START

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};


const verifyToken = (serviceToken) => {

  /*
  
    if (!serviceToken) {
      return false;
    }

    const decoded = jwtDecode(serviceToken);
    return decoded.exp > (Date.now() / 1000);
  
  */

  return true

};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    api.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete api.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => { }
});

export const JWTProvider = ({ children }) => {


  //--------------------------------| lockdata

  const [lockdata, setlockdata] = useState({
    foto: "",
    username: "",
    link: "",
    ative: false,
  });

  const resetlockdata = async () => {

    setlockdata({

      foto: "",
      username: "",
      link: "",
      ative: false,

    })

  }


  const createlockdata = async (username, foto, link) => {

    setlockdata({

      foto: foto,
      username: username,
      link: link,
      ative: true,

    })

  }




  //--------------------------------| menus
  const [menus, setmenus] = useState({ items: [] });


  //--------------------------------| permissoes
  const [permissoes, setpermissoes] = useState([]);



  //--------------------------------| thememenu
  const [thememenu, setthememenu] = useState(false);


  //--------------------------------| tempdata

  const [tempdata, settempdata] = useState({});



  //--------------------------------| popUp

  const [popUp, setpopUp] = useState({
    showpop: false,
    type: "",
    text: "",
    haveFunction: false,
    function: null,
    oth: null,
  });

  const popUp_reset = () => {

    setpopUp({

      showpop: false,
      type: "",
      text: "",
      haveFunction: false,
      function: null,
      oth: null,
    })

  }


  const popUp_removerItem = async (f) => {

    setpopUp({

      showpop: true,
      type: "remover",
      text: "Deseja remover esse item?",
      haveFunction: true,
      function: f,
      oth: null,
    })

  }

  const popUp_alertaOK = async (text) => {

    setpopUp({

      showpop: true,
      type: "alerta",
      text: text,
      haveFunction: false,
      function: null,
      oth: null,
    })

  }


  const popUp_simcancelar = async (text, f) => {

    setpopUp({

      showpop: true,
      type: "simcancelar",
      text: text,
      haveFunction: true,
      function: f,
      oth: null,
    })

  }


  const popUp_simnao = async (text, f) => {

    setpopUp({

      showpop: true,
      type: "simnao",
      text: text,
      haveFunction: true,
      function: f,
      oth: "it has 2 functions",
    })

  }


  //--------------------------------| useronline

  const [useronline, setuseronline] = useState(null);


  //--------------------------------| Auth


  const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async (data) => {

    const { serviceToken, user } = data;
    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        user
      }
    });

    reloaduser()

  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const reloaduser = async () => {


    try {

      const response = await api.get('/me');

      dispatch({
        type: ACCOUNT_INITIALISE,
        payload: {
          isLoggedIn: true,
          user: response.data[0]
        }
      });

      var s = maker0(response.data[0].glbperfil.glbmenu)
      maker1(s)
      var x = maker2(s)
      setmenus(x)

      setpermissoes(response.data[0].glbperfil.glbmenu)


    } catch (e) {

      console.log(e)

    }

  };

  useEffect(() => {


    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);

          const response = await api.get('/me');

          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: response.data[0]
            }
          });

          var s = maker0(response.data[0].glbperfil.glbmenu)
          maker1(s)
          var x = maker2(s)
          setmenus(x)

          setpermissoes(response.data[0].glbperfil.glbmenu)

          console.log("SSSSSSSSSS")


          const socket = socketio(backendURL, { query: { token: serviceToken } })

          socket.on('standard', async data => {

            console.log(data)

            if (data == "useronline") setuseronline(Math.random())

            if (data == "Notificacao") {

              uploadlistnotificacao()

            }


          })




        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();

    uploadlistnotificacao()



  }, []);


  //notificacao -------------------------

  const [notificacao, setnotificacao] = useState([]);
  const [countNotification, setCountNotification] = useState(0);


  async function uploadlistnotificacao() {

    try {

      const response = await api.get('/glbnotificacao');

      if (response.status == '200') {
        let data = response.data['data']
        setCountNotification(response.data.countNew)
        for (var i = 0; i < data.length; i++) {


          if (data[i]?.sgigjpessoa?.sgigjrelpessoaentidade.length > 0) {

            if (data[i]?.sgigjpessoa?.sgigjrelpessoaentidade[0].glbuser.length > 0) {

              data[i].URL_FOTO = data[i]?.sgigjpessoa?.sgigjrelpessoaentidade[0].glbuser[0].URL_FOTO


            } else data[i].URL_FOTO = ""

          } else data[i].URL_FOTO = ""



        }

        setnotificacao(data)

      }

      console.log(response)

    } catch (err) {

      console.error(err.response)


    }

  }


  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{
      ...state,

      login,
      logout,

      thememenu,
      setthememenu,

      tempdata,
      settempdata,

      reloaduser,

      menus,
      permissoes,

      popUp,
      popUp_reset,
      popUp_simnao,
      popUp_alertaOK,
      popUp_simcancelar,
      popUp_removerItem,

      lockdata,
      createlockdata,
      resetlockdata,

      useronline,
      notificacao,
      setnotificacao,
      uploadlistnotificacao,
      countNotification
    }}>

      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;